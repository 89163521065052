/* eslint-disable */
import { MsalProvider} from "@azure/msal-react";
import React from 'react';
import ReactDOM from 'react-dom';

import { msalInstance } from './auth';
import App from './App';
import './index.css';

  const AppProvider = () => (
    <MsalProvider instance={msalInstance}>
        <App />
    </MsalProvider>
  );
  ReactDOM.render(<AppProvider />, document.getElementById('root'));
