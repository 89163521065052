/* eslint-disable */
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import IconButton from '@material-ui/core/IconButton';
import Switch from '@material-ui/core/Switch';
import CancelIcon from '@material-ui/icons/Cancel';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import Dialog from '@material-ui/core/Dialog';
import { DialogContent, DialogActions } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import React from 'react';
import { getTags, deleteArticle, postArticle, postTag, requestPresignedUrlForFile, uploadFileWithPresignedUrl } from '../../apiService';
import '../../App.css';
import AutoCompleteDropDown from '../../components/AutoCompleteDropDown';
import DialogPopup from '../../components/Dialog';
import ListComponent from '../../components/List';
import NotificationDialog from '../../components/NotificationDialog/index.js';
import TextEditor from '../../components/TextEditor';
import { getEnvironments, isValidFileName } from '../../utils';
import { decode } from 'base-64';
import config from '../../config.js';
const STATIC_LOWER_BUCKET = config.buckets.staticLowerBucket

export default class CreateArticleComponent extends React.Component {
    constructor(props) {
        super(props);
        this.sourceEnvironment = getEnvironments().sourceEnvironment;
        this.state = {
            articleContent: [],
            articleDate: new Date(),
            articleDescription: '',
            articleFormat: '',
            articleId: '',
            articleTitle: '',
            articleUpdate: false,
            articleFiles: [],
            creatingArticle: false,
            deletedSubjectTags: [],
            deletedVehicleTags: [],
            embeddedImages: [],
            fileName: '',
            fileNameIsUpdated: false,
            internalArticle: false,
            isCKEditor: true,
            keywords: [],
            newModelYearArticleType: 'none',
            oldModelYearArticleType: 'none',
            open: false,
            selectedSubjectTags: [],
            selectedVehicleTags: [],
            selectedGroupTags: [],
            subjectTagList: [],
            textContent: this.props.textContent || '',
            thumbnail: null,
            thumbnailUrl: null,
            vehicleTagList: [],
            groupTags: [],
            videoHideShare: false,
            isRecommended: false,
            lastPromotedArticleId: ''
        };

        this.isValidFileName = isValidFileName.bind(this);
        this.publishArticle = this.publishArticle.bind(this);
        this.displayFileReference = this.displayFileReference.bind(this)
        this.replaceFile = this.replaceFile.bind(this)
    }

    onEditorStateChange = editorState => {
        this.removeDeletedEmbeddedImages(editorState);
        this.setState({
            textContent: editorState
        });
    };

    removeDeletedEmbeddedImages = textEditorString => {
        if (this.state.articleFiles) {
            this.state.articleFiles.forEach(file => {
                if ((typeof file === 'object') && textEditorString.indexOf(file.name) < 0) {
                    this.removeFile(file.name);
                }
                else if ((typeof file === 'string') && textEditorString.indexOf(file) < 0) {
                    this.removeFile(file);
                }
            })
        } 
    }

    updateInternalArticle = () => {
        if (this.state.internalArticle) {
            // Article is becoming customer facing, so add the 'Customer Sharing' tag
            this.addSubjectTag(null, 'Customer Sharing');
            this.setState({
                internalArticle: false,
            });
        }
        // The article is becoming internal, so remove the 'Customer Sharing' tag
        else {
            this.removeListItem('Customer Sharing', this.state.selectedSubjectTags, 'subject');
            if (this.state.oldModelYearArticleType === 'cfa') {
                this.handlePublishResponse(`Internal articles cannot be CFA's. Please change the MY20 category before publishing.`);
                this.setState({
                    oldModelYearArticleType: 'none',
                    internalArticle: true,
                });
            }
            else {
                this.setState({
                    internalArticle: true,
                });
            }
        }
    }

    updateVideoHideShare = () => {
        if (this.state.videoHideShare) {
            this.setState({
                videoHideShare: false,
            });
        }
        else {
            this.setState({
                videoHideShare: true,
            });
        }
    }

    updateIsRecommended = () => {
        this.setState({
            isRecommended: !this.state.isRecommended,
        });
    }

    removeListItem = (listItem, list, listType) => {
        list = list.slice();
        let index = list.indexOf(listItem);
        if (index > -1) {
            list.splice(index, 1)
        }
        switch (listType.toLowerCase()) {
            case 'subject':
                if (this.state.subjectTagList.indexOf(listItem) > -1) {
                    if (this.state.deletedSubjectTags.indexOf(listItem.toLowerCase()) === -1) {
                        this.state.deletedSubjectTags.push(listItem.toLowerCase());
                    }
                }
                this.setState({
                    selectedSubjectTags: list
                });
                break;
            case 'vehicle':
                if (this.state.vehicleTagList.indexOf(listItem) > -1) {
                    if (this.state.deletedVehicleTags.indexOf(listItem.toLowerCase()) === -1) {
                        this.state.deletedVehicleTags.push(listItem.toLowerCase());
                    }
                }
                this.setState({
                    selectedVehicleTags: list
                });
                break;
            case 'keywords':
                this.setState({
                    keywords: list
                });
                break;
            default:
                break;
        }
    }

    addDeletedSubjectTag = (tag) => {
        if (this.state.subjectTagList.indexOf(tag) > -1 && this.state.deletedSubjectTags.indexOf(tag.toLowerCase()) === -1 && this.props.updateArticle) {
            return [...this.state.deletedSubjectTags].concat(tag.toLowerCase());
        }
        return [...this.state.deletedSubjectTags];
    }

    addDeletedVehicleTag = (tag) => {
        if (this.state.vehicleTagList.indexOf(tag) > -1 && this.state.deletedVehicleTags.indexOf(tag.toLowerCase()) === -1 && this.props.updateArticle) {
            return [...this.state.deletedVehicleTags].concat(tag.toLowerCase());
        }
        return [...this.state.deletedVehicleTags];
    }

    removeSingleListItem = (listItem, list, listType) => {
        const newList = list.filter(tempListItem => tempListItem !== listItem);
        switch (listType.toLowerCase()) {
            case 'subject':
                const deletedSubjectTags = this.addDeletedSubjectTag(listItem);
                this.setState({
                    deletedSubjectTags,
                    selectedSubjectTags: newList,
                });
                break;
            case 'vehicle':
                const deletedVehicleTags = this.addDeletedVehicleTag(listItem);
                this.setState({
                    deletedVehicleTags,
                    selectedVehicleTags: newList
                });
                break;
            case 'group':
                const group = this.state.groupTags.find(tagGroup => tagGroup.groupName === listItem);
                let selectedTagVehicles = [];
                let selectedTagSubjects = [];
                newList.forEach(groupName => {
                    const curGroup = this.state.groupTags.find(group => group.groupName === groupName);
                    selectedTagVehicles.push(...curGroup.vehicleTags);
                    selectedTagSubjects.push(...curGroup.subjectTags);
                });

                const updatedSubjectList = this.state.selectedSubjectTags.filter(i => group.subjectTags && (!group.subjectTags.includes(i) || selectedTagSubjects.includes(i)));
                const updatedVehicleList = this.state.selectedVehicleTags.filter(i => group.vehicleTags && (!group.vehicleTags.includes(i) || selectedTagVehicles.includes(i)));
                this.setState({
                    selectedGroupTags: newList,
                    selectedVehicleTags: updatedVehicleList,
                    selectedSubjectTags: updatedSubjectList,
                })
                break;
            case 'keywords':
            default:
                this.setState({
                    keywords: newList
                });
                break;
        }
    }

    formatArticleContentForUpload = () => {
        let articleContent = [];
        let articleId = this.createArticleId(this.state.articleTitle, this.state.articleDate);
        let textContent = this.state.textContent;
        textContent = textContent.replace('cf.eshowroom-stage.deops.toyota.com', `${this.sourceEnvironment}.engage.toyota.com`);
        textContent = textContent.replace(/<iframe .+?videoId=(\d{13}).*?<\/iframe>/g, '{{$1}}');
        // can't leave the video as an iframe for publication because it wouldn't fire click analytics for engage
        if (textContent.indexOf('qumucloud') > -1) {

            textContent = this.removeCKEditorEmbedStyling(textContent);

            textContent = textContent.replace(/<iframe .+?video-toyota.qumucloud.com\/view\/([\w\d]{5,22}).*?<\/iframe>/g, '{{$1}}');
        }

        //convert embedded base64 images into normal image files and prepare them for upload to S3
        if (textContent.indexOf('base64') > -1) {

            const b64UrlRegex = new RegExp(/data:image\/([a-zA-Z]{3,4});base64,([a-zA-Z0-9+\/=]*)/g);
            const b64UrlNonGlobalRegex = new RegExp(/data:image\/([a-zA-Z]{3,4});base64,([a-zA-Z0-9+\/=]*)/);

            const b64Results = textContent.match(b64UrlRegex);
            for (let i = 0; i < b64Results.length; i++) {

                const file = this.convertB64UrlToFile(b64Results[i]);

                //once the image file is created from the b64 encoded image, we can replace the b64 string with the image's url
                const bucketEnv = window.location.hostname.includes('test') || window.location.hostname.includes('dev') || window.location.hostname.includes('localhost') ? "dev.nonprod" : "staging";
                const imageUrl = `https://${bucketEnv}.engage.toyota.com/static/articles/${articleId}/${file.name}`;
                textContent = textContent.replace(b64UrlNonGlobalRegex, `${imageUrl}`);

                //add the file object to the state so that it gets uploaded to S3 correctly
                this.state.embeddedImages.push(file);
            }


        }

        let windowText = new RegExp(/style=\'color: windowtext;\'/gi);
        textContent = textContent.replace(windowText, '');
        let videoReg = new RegExp(/\{\{(.*?)\}\}/g);
        let linkReg = new RegExp(/\(\*(.*?)\*\)/g);
        // replace temporary embedded image URL with permanent published image URL
        textContent = textContent.replace(/embeddedImages/g, articleId)

        let videos = textContent.match(videoReg);
        let links = textContent.match(linkReg);

        let indexMap = {};

        if (videos) {
            videos.forEach(videoValue => {
                videoValue = videoValue.replace(/\{\{/, '');
                videoValue = videoValue.replace(/\}\}/, '');
                let index = textContent.indexOf(videoValue);
                indexMap[index] = {
                    'type': 'Video',
                    'value': videoValue
                };
            });
        }
        if (links) {
            links.forEach(linkValue => {
                linkValue = linkValue.replace(/\(\*/, '');
                linkValue = linkValue.replace(/\*\)/, '');
                let index = textContent.indexOf(linkValue);
                indexMap[index] = {
                    'type': 'Link',
                    'value': linkValue
                };
            });
        }
        textContent = textContent.replace(/\{\{/g, '');
        textContent = textContent.replace(/\}\}/g, '');
        textContent = textContent.replace(/\(\*/g, '');
        textContent = textContent.replace(/\*\)/g, '');

        let indexArray = Object.keys(indexMap).sort((a, b) => a - b);

        indexArray.forEach(indexItem => {
            let indexObject = indexMap[indexItem]
            let items = textContent.split(indexObject.value);
            if (((items[0] !== '<p>') && (items[0] !== '</p><p><br></p><p>') && (items[0] !== '\n\n<p></p><p></p>\n\n') && (items[0] !== '\n\n'))) {
                articleContent.push({
                    'contentType': 'Text',
                    'contentValue': items[0]
                });
            }

            articleContent.push({
                'contentType': indexObject.type,
                'contentValue': indexObject.value
            })
            textContent = items[1];
        })
        if ((textContent !== '</p>') && (textContent !== '\n\n')) {
            articleContent.push({
                'contentType': 'Text',
                'contentValue': textContent
            });
        }
        if (this.state.articleFiles) {
            this.state.articleFiles.forEach(file => {
                const contentItem = {
                    contentType: 'File',
                    contentValue: '/static/articles/' + articleId + '/' + file.name
                };
                articleContent.unshift(contentItem);
            });
        }
        if (this.state.previouslyUploadedFiles) {
            this.state.previouslyUploadedFiles.map(filepath => {
                const fileRef = {
                    contentType: 'File',
                    contentValue: filepath,
                };
                articleContent.unshift(fileRef);
            })
        }
        return articleContent;
    }

    updateDate = (event, date) => {
        this.setState({
            articleDate: new Date(date)
        });
    }

    updateThumbnailImage = event => {
        let file = event.target.files[event.target.files.length - 1];
        this.setState({
            thumbnail: file,
            thumbnailUrl: null,
        });
    }

    removeFile = name => {
        const updatedFileList = this.state.articleFiles.filter(file => file.name != name);
        this.setState({
            articleFiles: updatedFileList,
        });

    }

    removeFileReference = filePath => {
        if (this.state.previouslyUploadedFiles) {
            const updatedReferenceList = this.state.previouslyUploadedFiles.filter(path => path != filePath);
            this.setState({
                previouslyUploadedFiles: updatedReferenceList,
            });
        }
    }

    removeThumbnail = () => {
        this.setState({
            thumbnail: null,
            thumbnailUrl: null,
        });
    }

    replaceFile = newFileName => {
        return this.state.previouslyUploadedFiles?.find(path => {
            const splitPath = path.split('/');
            return splitPath[splitPath.length - 1] == newFileName;
        })
    }

    addArticleFile = event => {
        let file;
        let key;
        if (event.name) {
            file = event;
            key = event.name;
        }
        else {
            file = event.target.files[0];
            key = file.name;
        }
        const existingFilePath = this.replaceFile(key)
        if (existingFilePath) {
            this.removeFileReference(existingFilePath)
        }
        if (isValidFileName(key)) {
            let files = this.state.articleFiles ? this.state.articleFiles : [];
            files.push(file);
            this.setState({
                articleFiles: files,
            });
        }
        else {
            this.handlePublishResponse(`The file name "${key}" is invalid because it contains special characters or spaces. File names should include only letters, numbers, underscores, periods, and dashes. Please update the file name and try again.`);
            return false;
        }
        return true;
    }

    handleOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    handleCKEditorChange = (event) => {
        this.setState({
            isCKEditor: event.target.checked
        })
    }

    getExistingTagList = listType => {
        return new Promise((resolve, reject) => {
            try {
                getTags(listType)
                    .then(response => {
                        return resolve(response.data);
                    })
            } catch (ex) {
                this.handlePublishResponse('Error retrieving existing tags. Please reload the page and try again.');
                return reject(ex);
            }
        })
    }

    updateSubjectTagList = newTag => {
        var tagList = this.state.subjectTagList;
        tagList.push(newTag);
        var selectedTags = this.state.selectedSubjectTags;
        selectedTags.unshift(newTag);
        this.setState({
            subjectTagList: tagList,
            selectedSubjectTags: selectedTags
        });
        return tagList;
    }

    updateVehicleTagList = newTag => {
        const tagList = this.state.vehicleTagList;
        tagList.push(newTag.trim());
        const selectedTags = this.state.selectedVehicleTags;
        selectedTags.unshift(newTag);
        this.setState({
            vehicleTagList: tagList,
            selectedVehicleTags: selectedTags
        });
        return tagList;
    }

    postNewArticleTag = (tagFile, tagType) => {
        return new Promise((resolve, reject) => {
            try {
                let body = {
                    'tagList': tagType,
                    'key': tagFile
                }
                postTag(body)
                    .then(response => {
                        return resolve(response);
                    })
            } catch (ex) {
                this.handlePublishResponse(`Error creating the new tag. Please try again.`);
                return reject(ex);
            }
        })
    }

    createNewSubjectTag = newTag => {
        if (this.tagDoesNotExist(newTag.trim(), this.state.subjectTagList)) {
            if (newTag.trim().length > 0) {
                var tagList = this.updateSubjectTagList(newTag);
                this.postNewArticleTag('subjectTags.json', tagList);
            }
        }
    }

    createNewVehicleTag = newTag => {
        if (this.tagDoesNotExist(newTag.trim(), this.state.vehicleTagList)) {
            if (newTag.trim().length > 0) {
                var tagList = this.updateVehicleTagList(newTag);
                this.postNewArticleTag('vehicleTags.json', tagList);
            }
        }
    }

    tagDoesNotExist = (newTag, tagList) => {
        return !tagList.includes(newTag);
    }

    addVehicleTagToArticle = (event, item) => {
        if (this.state.selectedVehicleTags.indexOf(item) === -1) {
            this.setState({
                selectedVehicleTags: [item, ...this.state.selectedVehicleTags]
            });
        }
    }

    formatDeletedVehicleTags = deletedVehicleTags => {
        if (deletedVehicleTags.length > 0) {
            const tags = deletedVehicleTags.map(tag => {
                const index = tag.indexOf(' ');
                const year = tag.slice(0, index);
                const model = tag.slice(index + 1);
                return (`${model}-${year}`)
            });
            return tags;
        }
    }

    addSubjectTag = (event, item) => {
        const selectedSubjectTags = this.state.selectedSubjectTags;
        if (selectedSubjectTags.indexOf(item) === -1) {
            this.setState({
                selectedSubjectTags: [item, ...selectedSubjectTags]
            });
        }
    }

    addTagGroup = (event, item) => {
        const selectedGroups = this.state.selectedGroupTags;
        const selectedSubjectTags = this.state.selectedSubjectTags;
        const selectedVehicleTags = this.state.selectedVehicleTags;

        const newGroup = this.state.groupTags.find(group => group.groupName === item);
        if (selectedGroups.indexOf(item) === -1 && newGroup) {
            const updatedSubjectTags = newGroup.subjectTags ? selectedSubjectTags.concat(newGroup.subjectTags).filter((v, i, a) => a.indexOf(v) === i) : [];
            const updatedVehicleTags = newGroup.vehicleTags ? selectedVehicleTags.concat(newGroup.vehicleTags).filter((v, i, a) => a.indexOf(v) === i) : [];
            this.setState({
                selectedSubjectTags: updatedSubjectTags,
                selectedVehicleTags: updatedVehicleTags,
                selectedGroupTags: [item, ...selectedGroups]
            });
        }
    }

    updateArticleTitle = event => {
        this.setState({
            articleTitle: event.target.value
        });
    }

    createArticleId = (articleTitle, date) => {
        let articleId = `${date.getMonth() + 1}_${date.getDate()}_${date.getFullYear()}_${articleTitle.toLowerCase()}`;
        articleId = articleId.replace(/\$/g, 's');
        articleId = articleId.replace(/ /g, '_');
        articleId = articleId.replace(/[^\w]/g, '');
        return articleId;
    }

    updateArticleId = event => {
        this.setState({
            articleId: event.target.value
        });
    }

    updateArticleDescription = event => {
        this.setState({
            articleDescription: event.target.value
        });
    }

    updateVideoId = event => {
        this.setState({
            videoId: event.target.value
        });
    }

    addKeyword = newKeyword => {
        if (!this.isDuplicateKeyword(newKeyword)) {
            this.setState({
                keywords: this.state.keywords.concat(newKeyword),
            });
        }
    }

    isDuplicateKeyword = keyword => {
        return this.state.keywords.includes(keyword);
    }

    changeOldModelYearArticleType = event => {
        let isInternalArticle = this.state.internalArticle;
        const updatedCategory = event.target.textContent.toLowerCase();
        if(this.state.oldModelYearArticleType === updatedCategory) {
            this.setState({
                oldModelYearArticleType: "none"
            });
        } else {
            if (updatedCategory === 'cfa') {
                if (isInternalArticle) {
                    this.handlePublishResponse(`This article is currently marked as an internal article. Choosing the "CFA" category will add the "Customer Sharing" tag. Click 'OK' to continue.`);
                }
                isInternalArticle = false;
                this.addSubjectTag('Customer Sharing');
            }
            else {
                this.removeListItem('Customer Sharing', this.state.selectedSubjectTags, 'subject');
            }
            this.setState({
                oldModelYearArticleType: updatedCategory,
                internalArticle: isInternalArticle,
            });
        }
    }

    changeNewModelYearArticleType = event => {
        const updatedCategory = event.target.textContent === 'FFB' ? 'feature function benefits' : event.target.textContent.toLowerCase();

        if(this.state.newModelYearArticleType === updatedCategory) {
            // If the user clicks the currently selected category it will be deselected
            this.setState({
                newModelYearArticleType: "none",
            });
        } else {
            this.setState({
                newModelYearArticleType: updatedCategory,
            });
        }
    }

    changeArticleFormat = event => {
        this.setState({
            articleFormat: event.target.textContent,
        });
    }

    // because we want inline images to show up before an article's ID is set (while the user is still editing the content),
    // I added a special function directing embedded image uploads to go into an /embeddedImages subfolder in s3 that is
    // independent of article ID's. one the article is being published with an ID, the embedded image will be copied to the 
    // appropriate article-ID-specific s3 folder.
    uploadInlineImageToS3 = file => {
        return new Promise((resolve, reject) => {
            this.setState({
                loadingEmbeddedImage: true,
            });
            const requestBody = {
                key: file && `static/articles/embeddedImages/${file.name}`,
                bucket: STATIC_LOWER_BUCKET,
            }
            this.sendFileUploadOverHttps(requestBody, file)
                .then(response => {
                    const updatedEmbeddedImagesArray = this.state.embeddedImages.concat(file);
                    this.setState({
                        loadingEmbeddedImage: false,
                        embeddedImages: updatedEmbeddedImagesArray,
                    });
                    if (response.status > 200) {
                        reject(response);
                    }
                    resolve(response);
                });
        });
    }

    uploadFileAttachmentToS3 = file => {
        if (file) {
            return new Promise((resolve, reject) => {
                let key;
                if (file.name && file.name.indexOf('/') > -1) {
                    key = file.name;
                } else if (!file.name) {
                    key = file;
                } else {
                    key = 'static/articles/' + this.createArticleId(this.state.articleTitle, this.state.articleDate) + '/' + file.name;
                }
                let requestBody = {
                    key: key,
                    bucket: STATIC_LOWER_BUCKET,
                }
                this.sendFileUploadOverHttps(requestBody, file)
                    .then(response => {
                        if (response.status > 200) {
                            reject(response);
                        }
                        resolve(null);
                    });

            });
        }
    }

    sendFileUploadOverHttps = (request, file) => {
        try {
            return new Promise((resolve, reject) => {
                requestPresignedUrlForFile(request)
                    .then(response => {
                        const signedUrl = response.data;
                        if (response.status > 200) {
                            reject(response);
                        }
                        uploadFileWithPresignedUrl(signedUrl, file.name, file)
                            .then(response => {
                                if (response.status > 200) {
                                    reject(response);
                                }
                                else {
                                    resolve(response);
                                }
                            });
                    });
            });
        }
        catch (ex) {
            alert('Something went wrong with the file upload. Please try again');
            console.log(ex.stack);
        }

    }

    postArticleData = () => {
        return new Promise((resolve, reject) => {
            try {
                let body = {
                    'title': this.state.articleTitle.trim(),
                    'articleId': this.createArticleId(this.state.articleTitle, this.state.articleDate),
                    'categories': this.getCategoryList(this.state.oldModelYearArticleType, this.state.newModelYearArticleType),
                    'createdDate': this.state.articleDate.toString(),
                    'articleFormat': (this.state.articleFormat !== 'none' && this.state.articleFormat !== '') ? this.state.articleFormat : null,
                    'description': this.state.articleDescription ? this.state.articleDescription.trim() : null,
                    'isCKEditor': this.state.isCKEditor,
                    'vehicleTags': (this.state.selectedVehicleTags && this.state.selectedVehicleTags.length > 0) ? this.state.selectedVehicleTags : null,
                    'subjectTags': (this.state.selectedSubjectTags && this.state.selectedSubjectTags.length > 0) ? this.state.selectedSubjectTags : null,
                    'keywords': this.state.keywords,
                    'language': 'en',
                    'thumbnail': this.state.thumbnail ? (this.state.thumbnail.name ? this.state.thumbnail.name.trim() : this.state.thumbnail) : null,
                    'thumbnailUrl': this.state.thumbnailUrl,
                    'content': this.formatArticleContentForUpload(),
                    'deletedVehicleTags': (this.state.deletedVehicleTags && this.state.deletedVehicleTags.length > 0) ? this.formatDeletedVehicleTags(this.state.deletedVehicleTags) : null,
                    'deletedSubjectTags': (this.state.deletedSubjectTags && this.state.deletedSubjectTags.length > 0) ? this.state.deletedSubjectTags : null,
                    'videoHideShare': this.state.videoHideShare,
                    'isRecommended': this.state.isRecommended,
                    'internalArticle': this.state.internalArticle,
                    'lastPromotedArticleId': this.state.lastPromotedArticleId
                };
                console.log(body);
                postArticle(body)
                    .then(response => {
                        if (response.status > 200) {
                            reject(response.data);
                        }
                        else {
                            let allArticleFiles = [];
                            if (this.state.articleFiles) {
                                this.state.articleFiles.forEach(file => {
                                    allArticleFiles.push(file)
                                });
                            }
                            if (this.state.embeddedImages) {
                                this.state.embeddedImages.forEach(image => {
                                    allArticleFiles.push(image)
                                });
                            }
                            if (!this.state.thumbnailUrl && this.state.thumbnail) {
                                allArticleFiles.push(this.state.thumbnail);
                            }
                            console.log("allArticleFiles ", allArticleFiles)
                            const articlePromiseArray = allArticleFiles.map(file => {
                                return this.uploadFileAttachmentToS3(file)
                                    .then(err => {
                                        if (err) {
                                            console.log(err);
                                        }
                                        return (null);
                                    });
                            });
                            console.log("articlePromiseArray", articlePromiseArray)
                            return Promise.all(articlePromiseArray).then(results => {
                                const isNull = value => value === null;
                                console.log("results ", results)
                                if (results.every(isNull)) {
                                    resolve(null);
                                }
                                resolve(results);
                            });
                        }
                    })
                    .catch(err => {
                        throw err;
                    })
            }
            catch (ex) {
                this.handlePublishResponse(`Error uploading the article data: ${ex}`);
            }
        });
    }

   callDeleteArticle(articleId, environment) {
        return new Promise((resolve, reject) => {

            let language = 'en';
            let env = environment.toLowerCase();

            deleteArticle(articleId, language, env)
                .then(response => {
                    if (response) {
                        resolve(response);
                    }
                    else {
                        resolve('Error: No response from API');
                    }
                }).catch(error => {
                    resolve('Error occured: ' + error);
                });
        });
    }

    handlePublishResponse = message => {
        this.setState({
            creatingArticle: false,
        });
        if (message) {
            this.notificationDialog.handleOpen(message);
        };
    }

    publishArticle = () => {
        try{
            this.verifyDeleteArticle();
            this.createArticle();
        }
        catch(ex){
            console.error(JSON.stringify(ex));
            this.handlePublishResponse('Error publishing the article. Please try again or reach out to the dev team');
        }
    }

    verifyDeleteArticle = async () => {
        try{
            const articleID = this.createArticleId(this.state.articleTitle, this.state.articleDate);
            if(this.props.updateArticle == true && this.props.articleId != "" && this.props.articleId != articleID){
                await this.callDeleteArticle(this.props.articleId, this.sourceEnvironment);
            }
            else{

            }
        }
        catch(ex){
            console.error(JSON.stringify(ex));
            this.handlePublishResponse('Error verifying deletiion or deleting the article. Please try again or reach out to the dev team');
        }
    }
    createArticle = () => {
        try {
            if (!this.state.articleTitle || (this.state.oldModelYearArticleType === 'none' && this.state.newModelYearArticleType === 'none')) {
                alert('Please fill in all required fields, including a title and at least one category');
            }
            else {
                this.setState({
                    creatingArticle: true
                }, (() => {
                    this.postArticleData()
                        .then((err) => {
                            if (err) {
                                throw err;
                            }
                            else {
                                this.handlePublishResponse('Article successfully created. Triggering page reload now');
                                let articleLink = "";
                                if(['dev', 'test'].includes(this.sourceEnvironment)){
                                    articleLink = `https://${this.sourceEnvironment}.nonprod.engage.toyota.com/articles/${this.createArticleId(this.state.articleTitle, this.state.articleDate)}`;
                                }
                                else {
                                    articleLink = `https://${this.sourceEnvironment}.engage.toyota.com/articles/${this.createArticleId(this.state.articleTitle, this.state.articleDate)}`;
                                }
                                window.open(articleLink);
                                window.location.reload();
                            }
                        });
                }))
            }
        }
        catch (ex) {
            console.error(JSON.stringify(ex));
            this.handlePublishResponse('Error creating the article. Please try again or reach out to the dev team');
        }
    }

    getCategoryList = (oldCategoryType, newCategoryType) => {
        let categoryList = [];
        const oldCat = oldCategoryType.toString().toLowerCase();
        const newCat = newCategoryType.toString().toLowerCase();

        if (this.isValidOldCategory(oldCat)) {
            categoryList.push(oldCat);
        }
        if (this.isValidNewCategory(newCat)) {
            categoryList.push(newCat);
        }
        if (this.state.internalArticle) {
            categoryList.push('internal articles');
        }

        const formattedCategories = categoryList ? categoryList.map(cat => cat.toLowerCase()) : null;
        return formattedCategories;
    }

    getOldArticleTypeFromCategories = categories => {
        const oldCat = categories ? categories.filter(cat => this.isValidOldCategory(cat.toLowerCase())) : null;
        const formattedCat = (oldCat && oldCat.length === 1) ? oldCat.toString().toLowerCase() : null;
        return formattedCat;
    }

    getNewArticleTypeFromCategories = categories => {
        const newCat = categories ? categories.filter(cat => this.isValidNewCategory(cat.toLowerCase())) : null;
        const formattedCat = (newCat && newCat.length === 1) ? newCat.toString().toLowerCase() : null;
        return formattedCat;
    }

    isValidNewCategory = category => {
        return (category === 'overview' || category === 'feature function benefits' || category === 'how to' || category === '3rd party' || category === 'article' || category === 'video' || category === 'news');
    }

    isValidOldCategory = category => {
        return (category === 'article' || category === 'video' || category === 'kmsm' || category === 'hot sheet' || category === 'cfa');
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.selectedVehicleTags !== this.state.selectedVehicleTags || prevState.selectedSubjectTags != this.state.selectedSubjectTags) {
            const groupsStatus = this.state.groupTags && this.state.groupTags.length > 0 ? this.state.groupTags.map(group => {
                const allSubjectTagsSelected = (group.subjectTags && group.subjectTags.length > 0) ? group.subjectTags.every(val => this.state.selectedSubjectTags.includes(val)) : true;
                const allVehicleTagsSelected = (group.vehicleTags && group.vehicleTags.length > 0) ? group.vehicleTags.every(val => this.state.selectedVehicleTags.includes(val)) : true;
                return {
                    groupName: group.groupName,
                    allSelected: allSubjectTagsSelected && allVehicleTagsSelected,
                }
            }) : null;
            const selectedGroups = groupsStatus ? groupsStatus.filter(v => v.allSelected).map(v => v.groupName) : [];
            this.setState({
                selectedGroupTags: selectedGroups,
            });
        }
    }

    componentDidMount = () => {
        Promise.all([this.getExistingTagList('subject'), this.getExistingTagList('vehicle'), this.getExistingTagList('group')])
            .then(response => {
                this.setState({
                    subjectTagList: response[0],
                    vehicleTagList: response[1],
                    groupTags: response[2],
                });
            });
        const updatedCategories = this.props.categories ? (typeof this.props.categories === 'string' ? JSON.parse(this.props.categories) : this.props.categories) : null;
        const oldCatProp = updatedCategories ? this.getOldArticleTypeFromCategories(updatedCategories) : null;
        const newCatProp = updatedCategories ? this.getNewArticleTypeFromCategories(updatedCategories) : null;
        this.setState({
            articleContent: this.props.articleContent || this.state.articleContent,
            articleDate: this.props.articleDate || this.state.articleDate,
            articleDescription: this.props.articleDescription || this.state.articleDescription,
            articleFormat: this.props.articleFormat || this.state.articleFormat,
            articleId: this.props.articleId || this.state.articleId,
            articleTitle: this.props.articleTitle || this.state.articleTitle,
            categories: this.props.categories || this.state.categories,
            internalArticle: this.props.internalArticle || this.state.internalArticle,
            isCKEditor: this.props.isCKEditor || false,
            keywords: this.props.keywords || this.state.keywords,
            newModelYearArticleType: newCatProp && newCatProp.length > 0 ? newCatProp.toString() : this.state.newModelYearArticleType,
            oldModelYearArticleType: oldCatProp && oldCatProp.length > 0 ? oldCatProp.toString() : this.state.oldModelYearArticleType,
            previouslyUploadedFiles: this.props.previouslyUploadedFiles || this.state.previouslyUploadedFiles,
            selectedSubjectTags: this.props.selectedSubjectTags || this.state.selectedSubjectTags,
            selectedVehicleTags: this.props.selectedVehicleTags || this.state.selectedVehicleTags,
            textContent: this.props.textContent || this.state.textContent,
            thumbnail: this.props.thumbnail || this.state.thumbnail,
            thumbnailUrl: this.props.thumbnailUrl || this.state.thumbnailUrl,
            videoHideShare: this.props.videoHideShare || this.state.videoHideShare,
            isRecommended: this.props.isRecommended || this.state.isRecommended,
            lastPromotedArticleId: this.props.lastPromotedArticleId || this.state.lastPromotedArticleId
        });
    }

    fileDisplay = () => {
        return (
            <div>
                {this.state.articleFiles?.map((file, index) => {
                        return (
                            <div className='upload-item-container' key={index}>
                                <div className='uploaded-item new-upload'> {file.name} </div>
                                <div style={{ display: 'inline-block' }}>
                                    <IconButton aria-label='Cancel' onClick={() => this.removeFile(file.name)}>
                                        <CancelIcon />
                                    </IconButton>
                                </div>
                            </div>
                        )
                    })}
                {this.displayFileReference(this.state.previouslyUploadedFiles)}
            </div>
        );
    }

    displayFileReference = filePaths => {
        return (
            filePaths?.map(path => {
                const splitPath = path.split('/');
                const filename = splitPath[splitPath.length - 1];
                return (
                    <div className='upload-item-container' key={path}>
                        <div className='uploaded-item'>{filename}</div>
                        <div style={{ display: 'inline-block' }}>
                            <IconButton aria-label='Cancel' onClick={() => this.removeFileReference(path)}>
                                <CancelIcon />
                            </IconButton>
                        </div>
                    </div>
                )
            })
        )
    }

    getThumbnailPath = name => {
        if (name.indexOf('images') > -1 || name.indexOf('static') > -1 || name.indexOf('articles') > -1) {
            return `${name}`;
        }
        else {
            const bucketEnv = window.location.hostname.includes('test') || window.location.hostname.includes('dev') || window.location.hostname.includes('localhost') ? "dev.nonprod" : "staging";
            return `https://${bucketEnv}.engage.toyota.com/static/articles${name}`;
        }
    }

    thumbnailElement = () => {
        if (this.state.thumbnail || this.state.thumbnailUrl) {
            return <div className='upload-item-container'>
                <div className='uploaded-item'>
                    {this.state.thumbnail && this.state.thumbnail.name || <img src={this.getThumbnailPath(this.state.thumbnailUrl)} />}
                </div>
                <div style={{ display: 'inline-block' }}>
                    <IconButton aria-label='Cancel' onClick={() => this.removeThumbnail()}>
                        <CancelIcon />
                    </IconButton>
                </div>
            </div>
        } else {
            return null;
        }
    }

    convertB64UrlToFile = (b64Data) => {

        //split the base 64 url into the data part and the prefix
        const splitString = b64Data.split(",");

        //iterate over the decoded b64 data and parse each character into an array of unsigned integers, which we can use to create a File
        const decodedString = decode(splitString[1]);
        let imgBinary = new Uint8Array(decodedString.length);

        const mimeType = "image/png"; //your filesystem will handle the file even if it's not a png, but if it causes problems the correct mime type is present in splitString[0]

        for (let j = 0; j < decodedString.length; j++) {
            imgBinary[j] = decodedString.charCodeAt(j);
        }

        // SonarQube suggestion to skip pseudorandom number generation
        const crypto = window.crypto || window.msCrypto;
        const cryptoArray = new Uint32Array(1);
        const randomNumber = crypto.getRandomValues(cryptoArray);
        //the name of the file can't be recovered from the base64, so generating a simple randomish one
        const fileName = Math.floor(randomNumber / 1000000).toString().padStart(4, '0') + ".png";

        const file = new File([imgBinary], fileName, { type: mimeType });

        return file;
    }

    removeCKEditorEmbedStyling = (textContent) => {

        textContent = textContent.replace(/<div data-oembed-url="https:\/\/video-toyota.qumucloud.com\/view\/([\w\d]{5,13}).*?>/g, '');
        textContent = textContent.replace(/\n<div style="left: 0; width: 100%; height: 0; position: relative; padding-bottom: 56.25%;">/g, '');

        return textContent;
    }


    render() {
        const actions = [
            <Button
                key="cancel-button"
                primary={true}
                onClick={this.handleClose}
            >Cancel</Button>,
            <Button
                key="upload-button"
                primary={true}
                keyboardFocused={true}
                onClick={this.uploadTemplate}
            >Upload</Button>,
        ];
        return (
            <div>
                <br />
                <Paper className='Paper' elevation={1}>
                    <div className='Left-Column'>
                        <div className='Article-Title'>
                            <TextField
                                style={{ display: 'none' }}
                                className='Article-Title-Input'
                                placeholder='Enter article title'
                                value={this.state.articleId}
                                onChange={this.updateArticleId}
                                label='Article ID'
                                disabled={false}
                                required={true}
                                helperText={this.state.articleId ? '' : 'This Field is Required'}
                            />
                        </div>
                        <div className='Article-Type'>
                            <p className='Form-Text'>MY20- Article Category</p>
                            <Button variant='contained' key='2' className='Toggle-Button' color={this.state.oldModelYearArticleType === 'article' ? 'primary' : 'default'} onClick={this.changeOldModelYearArticleType}>Article</Button>
                            <Button variant='contained' key='3' className='Toggle-Button' color={this.state.oldModelYearArticleType === 'video' ? 'primary' : 'default'} onClick={this.changeOldModelYearArticleType}>Video</Button>
                            <Button variant='contained' key='4' className='Toggle-Button' color={this.state.oldModelYearArticleType === 'kmsm' ? 'primary' : 'default'} onClick={this.changeOldModelYearArticleType}>KMSM</Button>
                            <Button variant='contained' key='5' className='Toggle-Button' color={this.state.oldModelYearArticleType === 'hot sheet' ? 'primary' : 'default'} onClick={this.changeOldModelYearArticleType}>Hot Sheet</Button>
                            <Button variant='contained' key='6' className='Toggle-Button' color={this.state.oldModelYearArticleType === 'cfa' ? 'primary' : 'default'} onClick={this.changeOldModelYearArticleType}>CFA</Button>
                        </div>
                        <br />
                        <br />
                        <div className='Article-Type'>
                            <p className='Form-Text'>MY21+ Article Category</p>
                            <Button variant='contained' key='2' className='Toggle-Button' color={this.state.newModelYearArticleType === 'overview' ? 'primary' : 'default'} onClick={this.changeNewModelYearArticleType} >Overview</Button>
                            <Button variant='contained' key='3' className='Toggle-Button' color={this.state.newModelYearArticleType === 'how to' ? 'primary' : 'default'} onClick={this.changeNewModelYearArticleType} >How To</Button>
                            <Button variant='contained' key='4' className='Toggle-Button' color={this.state.newModelYearArticleType === 'feature function benefits' ? 'primary' : 'default'} onClick={this.changeNewModelYearArticleType} >FFB</Button>
                            <Button variant='contained' key='5' className='Toggle-Button' color={this.state.newModelYearArticleType === '3rd party' ? 'primary' : 'default'} onClick={this.changeNewModelYearArticleType} >3rd Party</Button>
                        </div>
                        <div className='Article-Type'>
                            <p className='Form-Text'>MY23+ Article Category</p>
                            <Button variant='contained' key='2' className='Toggle-Button' color={this.state.newModelYearArticleType === 'article' ? 'primary' : 'default'} onClick={this.changeNewModelYearArticleType} >Article</Button>
                            <Button variant='contained' key='3' className='Toggle-Button' color={this.state.newModelYearArticleType === 'video' ? 'primary' : 'default'} onClick={this.changeNewModelYearArticleType} >Video</Button>
                            <Button variant='contained' key='4' className='Toggle-Button' color={this.state.newModelYearArticleType === 'news' ? 'primary' : 'default'} onClick={this.changeNewModelYearArticleType} >News</Button>
                        </div>
                        <br />
                        <br />
                        <div className='Article-Format'>
                            <p className='Form-Text'>Article Format (applies to MY21+ only)</p>
                            <Button variant='contained' key='2' className='Toggle-Button' disabled={this.state.newModelYearArticleType === 'none'} color={this.state.articleFormat === 'Article' ? 'primary' : 'default'} onClick={this.changeArticleFormat} >Article</Button>
                            <Button variant='contained' key='3' className='Toggle-Button' disabled={this.state.newModelYearArticleType === 'none'} color={this.state.articleFormat === 'Video' ? 'primary' : 'default'} onClick={this.changeArticleFormat} >Video</Button>
                            <Button variant='contained' key='4' className='Toggle-Button' disabled={this.state.newModelYearArticleType === 'none'} color={this.state.articleFormat === 'File' ? 'primary' : 'default'} onClick={this.changeArticleFormat} >File</Button>
                        </div>
                        <br />
                        <div className='Internal-Article'>
                            <Checkbox
                                checked={this.state.internalArticle}
                                onChange={() => this.updateInternalArticle()}
                                value='true'
                                color='primary'
                                disabled={false}
                            />
                            <span className='Internal-Article-Text'>Internal Article (applies to both MY20- and MY21+)</span>
                        </div>
                        <div className='Video-Hide-Share'>
                            <Checkbox
                                checked={this.state.videoHideShare}
                                onChange={() => this.updateVideoHideShare()}
                                value='true'
                                color='primary'
                                disabled={false}
                            />
                            <span className='Video-Hide-Share-Text'>Hide video Share and Download buttons</span>
                        </div>
                        <div className='Is-Recommended-Article'>
                            <Checkbox
                                checked={this.state.isRecommended}
                                onChange={() => this.updateIsRecommended()}
                                value='true'
                                color='primary'
                                disabled={false}
                            />
                            <span className='Is-Recommended-Article-Text'>Recommended Article</span>
                        </div>
                        <div className='Article-Title'>
                            <TextField
                                className='Article-Title-Input'
                                placeholder='Enter article title'
                                value={this.state.articleTitle}
                                onChange={this.updateArticleTitle}
                                label='Headline/Title'
                                required={true}
                                helperText={this.state.articleTitle ? '' : 'This Field is Required'}
                            />
                        </div>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                clearable
                                value={this.state.articleDate}
                                label='Article Published Date'
                                format="MM/dd/yyyy"
                                onChange={this.updateDate}
                                />
                        </MuiPickersUtilsProvider>
                        <TextField
                            className='Article-Description'
                            placeholder='Description'
                            label='Short Description'
                            multiline={true}
                            onChange={this.updateArticleDescription}
                            value={this.state.articleDescription}
                            maxRows={4}
                        /><br />
                        <div className="Form-Text">
                            <p>Article Content</p>
                            {this.state.loadingEmbeddedImage ? <p style={{ fontSize: '14px' }}>Loading image...</p> : null}
                        </div>
                        {
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={this.state.isCKEditor}
                                        onChange={this.handleCKEditorChange}
                                        value={this.state.isCKEditor}
                                        color="primary"
                                    />
                                }
                                label="Use CKEditor"
                            />
                        }
                        <TextEditor
                            isCKEditor={this.state.isCKEditor}
                            articleId={this.state.articleId ? this.state.articleId : this.createArticleId(this.state.articleTitle, this.state.articleDate)}
                            className='Text-Editor'
                            text={this.state.textContent}
                            contentUpdate={this.onEditorStateChange}
                            addFile={this.uploadInlineImageToS3}
                        />
                    </div>
                    <div className='Right-Column'>
                        <div className='Vehicle-Tags'>
                            {this.state.groupTags && this.state.groupTags.length > 0 &&
                                <React.Fragment>
                                    <div className='Tags-Dropdown'>
                                        <AutoCompleteDropDown className='Tags-Dropdown' title='Add Tag Group' items={this.state.groupTags.map(item => item.groupName)} onItemSelected={this.addTagGroup} />
                                    </div>
                                    <br />
                                    <ListComponent className='Tag-List' items={this.state.selectedGroupTags} removeListItem={this.removeSingleListItem} listType='Group' />
                                </React.Fragment>
                            }
                            {this.state.vehicleTagList && this.state.vehicleTagList.length > 0 &&
                                <React.Fragment>
                                    <div style={{ marginBottom: "10px" }} className='Tags-Dropdown'>
                                        <AutoCompleteDropDown className='Tags-Dropdown' title='Add Vehicle Tags' items={this.state.vehicleTagList} onItemSelected={this.addVehicleTagToArticle} />
                                        <DialogPopup className='Create-Tag' label='Create Tag' title='Create Vehicle Tag' hintText='Enter tag name' inputText='Vehicle Tag' onSubmitFunction={this.createNewVehicleTag.bind(this)} />
                                    </div>
                                    <br />
                                    <ListComponent className='Tag-List' items={this.state.selectedVehicleTags} removeListItem={this.removeListItem} listType='Vehicle' />
                                </React.Fragment>
                            }

                        </div>
                        {this.state.subjectTagList && this.state.vehicleTagList.length > 0 &&
                            <div className='Subject-Tags'>
                                <div style={{ marginBottom: "10px" }} className='Tags-Dropdown'>
                                    <AutoCompleteDropDown className='Tags-Dropdown' title='Add Subject Tags' items={this.state.subjectTagList} onItemSelected={this.addSubjectTag} />
                                    <DialogPopup label='Create Tag' title='Create Subject Tags' hintText='Enter tag name' inputText='Subject Tag' onSubmitFunction={this.createNewSubjectTag.bind(this)} />
                                </div>
                                <br />
                                <ListComponent className='Tag-List' items={this.state.selectedSubjectTags} removeListItem={this.removeListItem} listType='Subject' />
                            </div>
                        }
                        <br />
                        <div className='Keywords'>
                            <div style={{ marginBottom: "10px" }} className='Tags-Dropdown'>
                                <DialogPopup label='Add Keyword' title='Add Keyword' hintText='Enter Keyword' inputText='Keyword' onSubmitFunction={this.addKeyword.bind(this)} />
                            </div>
                            <br />
                            <ListComponent className='Tag-List' items={this.state.keywords} removeListItem={this.removeListItem} listType='Keywords' />
                        </div>
                        <br />
                        <Button variant='contained' color='primary'
                            component='label'>
                            <input type='file' style={{ display: 'none' }} onChange={this.updateThumbnailImage} />
                            Thumbnail Image:</Button>
                        <div style={{ textAlign: 'center' }}>{this.thumbnailElement()}</div>
                        <br />
                        <br />
                        <Button variant='contained' color='primary'
                            component='label'>
                            <input type='file' style={{ display: 'none' }} onChange={this.addArticleFile} />
                            Article File: </Button>
                        {this.fileDisplay()}
                        <br />
                        <br />
                        <br />
                        <br />
                        <Button variant='contained' style={{ float: 'left' }} color='secondary' disabled={this.state.creatingArticle} onClick={this.publishArticle} >Publish To {this.sourceEnvironment}</Button>
                    </div>
                    <div style={{ textAlign: 'center', width: '100%', float: 'right', marginTop: '30px' }}>
                        {this.state.creatingArticle ? <CircularProgress size={50} /> : null}
                    </div>
                </Paper>
                <Dialog
                    open={this.state.open}
                    onRequestClose={this.handleClose}>
                    <DialogContent>
                        {this.state.onSubmitTemplateDialogText}
                    </DialogContent>
                    <DialogActions>
                        {actions}
                    </DialogActions>
                </Dialog>
                <NotificationDialog
                    ref={dialogRef => (this.notificationDialog = dialogRef)}
                />
            </div >
        );
    }
}
