import { useState } from 'react';
import '../../App.css';
import { Button, TextField, Dialog, DialogContent, DialogActions, Card , CircularProgress } from '@material-ui/core';
import { createUserRequest } from '../../apiService';

function CreateGuestUserComponent() {
    const [isDisabled, setIsDisabled] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [submitGuestUserCreationText, setSubmitGuestUserCreationText] = useState('An Error Occurred');
    const [open, setOpen] = useState(false);

    const createGuestUser = () => {
        return new Promise((resolve, reject) => {

            // Expiration date for account is set to 1 year from creation date
            setIsDisabled(true);
            const today = new Date();
            const nextYear = today.getFullYear() + 1;
            const expirationDate = new Date(nextYear, today.getMonth(), today.getDate()).toISOString().split('T')[0];

            let body = {
                firstName: firstName, 
                lastName: lastName, 
                email: email, 
                expirationDate: expirationDate
            }

            createUserRequest(body)
                .then((response) => {
                    setIsDisabled(false);
                    if (response.errorMessage) {
                        reject(response);
                    }
                    else {
                        console.log("Success: Guest User created");
                        setSubmitGuestUserCreationText("User has been successfully created in ForgeRock.");
                        setOpen(true);
                        resolve(null);
                    }
                })
                .catch((error) => {
                    setIsDisabled(false);
                    console.log("Exception " + error);
                    setSubmitGuestUserCreationText("ERROR: User has not been successfully created in ForgeRock. Error encountered: " + error);
                    setOpen(true);
                    reject(error);
                });
        });
    };

    return (
        <>
        <div className="create-user-container">
            <Card style={{ padding: '30px' }}>
            <div className="create-user-item">
                            <p>First Name:</p>
                            <TextField value={firstName} fullWidth onChange={(event) => { setFirstName(event.target.value); } }>
                            </TextField>
                        </div><div className="create-user-item">
                                <p>Last Name:</p>
                                <TextField value={lastName} fullWidth onChange={(event) => { setLastName(event.target.value); } }>
                                </TextField>
                            </div><div className="create-user-item">
                                <p>Email:</p>
                                <TextField className="create-user-email" value={email} fullWidth onChange={(event) => { setEmail(event.target.value); } }>
                                </TextField>
                            </div>
            <Button style={{ marginTop: '20px' }} variant='contained' color={true ? 'primary' : 'default'} onClick={createGuestUser} disabled={!firstName || !lastName || !email || isDisabled  }>Create</Button>
            </Card>
            {isDisabled ? <CircularProgress style={{ margin: 'auto' }} size={80} color="inherit" /> : null }
        </div>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogContent>
                    <p>{submitGuestUserCreationText}</p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default CreateGuestUserComponent;