let env;
let config;
const hostname = window && window.location && window.location.hostname;

switch(hostname) {
  case 'mc.engage.toyota.com':
    env = 'prod';
    break;
  case 'test.mc.nonprod.engage.toyota.com':
    env = 'test';
    break;
  case 'dev.mc.nonprod.engage.toyota.com':
    env = 'dev';
    break;
  default:
    env = 'local';
    break;
}

switch(env) {
  case 'prod':
    config = {
      apiGateway: {
        REGION: 'us-west-2',
        URL: '/mcApi'
      },
      auth: {
        APPID: '6137a240-56d0-493f-9d87-45f2c9cc6884',
        AUTHORITY: 'https://login.microsoftonline.com/toyota1.onmicrosoft.com/',
        URL: 'toyota1.onmicrosoft.com',
        TENANTID: '8c642d1d-d709-47b0-ab10-080af10798fb',
        ENDPOINT: 'TOYOTA1.onmicrosoft.com/6137a240-56d0-493f-9d87-45f2c9cc6884',
        REDIRECT: 'https://mc.engage.toyota.com/',
        GraphUrl:'https://graph.microsoft.com/v1.0/me/transitiveMemberOf/microsoft.graph.group?$count=true'
      },
      cache: {
        cacheLocation: 'sessionStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to 'true' if you are having issues on IE11 or Edge
      },
      environments: {
        sourceEnvironment: "staging",
        formattedSourceEnvironment: "Staging",
        destinationEnvironment: "prod",
        formattedDestinationEnvironment: "Prod"
      },
      buckets: {
        staticLowerBucket: "es-uat-us-west-2-static",
        templatesHigherBucket: "es-prd-us-west-2-templates"
      },
      isProd: true,
    };
    break;
  case 'test':
    config = {
      apiGateway: {
        REGION: 'us-east-1',
        URL: '/mcApi'
      },
      auth: {
        APPID: '180d1c63-41f1-445e-943c-18d937bd6410',
        AUTHORITY: 'https://login.microsoftonline.com/tmnatest.onmicrosoft.com/',
        URL: 'tmnatest.onmicrosoft.com',
        TENANTID: '9107b728-2166-4e5d-8d13-d1ffdf0351ef',
        ENDPOINT: 'tmnatest.onmicrosoft.com/180d1c63-41f1-445e-943c-18d937bd6410',
        REDIRECT: 'https://test.mc.nonprod.engage.toyota.com/',
        GraphUrl:'https://graph.microsoft.com/v1.0/me/transitiveMemberOf/microsoft.graph.group?$count=true'
      },
      cache: {
        cacheLocation: 'sessionStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to 'true' if you are having issues on IE11 or Edge
      },
      environments: {
        sourceEnvironment: "dev",
        formattedSourceEnvironment: "Dev",
        destinationEnvironment: "test",
        formattedDestinationEnvironment: "Test"
      },
      buckets: {
        staticLowerBucket: "es-dev-us-east-1-static",
        templatesHigherBucket: "es-sit-us-east-1-templates"
      },
      isProd: false,
    };
    break;
  case 'dev':
    config = {
      apiGateway: {
        REGION: 'us-east-1',
        URL: '/mcApi'
      },
      auth: {
        APPID: 'eede59e9-ea25-47b8-9841-01ca8b5f9c52',
        AUTHORITY: 'https://login.microsoftonline.com/tmnatest.onmicrosoft.com/',
        URL: 'tmnatest.onmicrosoft.com',
        TENANTID: '9107b728-2166-4e5d-8d13-d1ffdf0351ef',
        ENDPOINT: 'tmnatest.onmicrosoft.com/eede59e9-ea25-47b8-9841-01ca8b5f9c52',
        REDIRECT: 'https://dev.mc.nonprod.engage.toyota.com/',
        GraphUrl:'https://graph.microsoft.com/v1.0/me/transitiveMemberOf/microsoft.graph.group?$count=true'

      },
      cache: {
        cacheLocation: 'sessionStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to 'true' if you are having issues on IE11 or Edge
      },
      environments: {
        sourceEnvironment: "dev",
        formattedSourceEnvironment: "Dev",
        destinationEnvironment: "test",
        formattedDestinationEnvironment: "Test"
      },
      buckets: {
        staticLowerBucket: "es-dev-us-east-1-static",
        templatesHigherBucket: "es-sit-us-east-1-templates"
      },
      isProd: false,
    };
    break;
  case 'local':
    config = {
      apiGateway: {
        REGION: 'us-east-1',
        URL: 'https://dev.mc.nonprod.engage.toyota.com/mcApi'
      },
      auth: {
        APPID: 'eede59e9-ea25-47b8-9841-01ca8b5f9c52',
        AUTHORITY: 'https://login.microsoftonline.com/tmnatest.onmicrosoft.com/',
        URL: 'tmnatest.onmicrosoft.com',
        TENANTID: '9107b728-2166-4e5d-8d13-d1ffdf0351ef',
        ENDPOINT: 'tmnatest.onmicrosoft.com/eede59e9-ea25-47b8-9841-01ca8b5f9c52',
        REDIRECT: 'http://localhost:3000',
        GraphUrl:'https://graph.microsoft.com/v1.0/me/transitiveMemberOf/microsoft.graph.group?$count=true'
      },
      cache: {
        cacheLocation: 'sessionStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to 'true' if you are having issues on IE11 or Edge
      },
      environments: {
        sourceEnvironment: "dev",
        formattedSourceEnvironment: "Dev",
        destinationEnvironment: "test",
        formattedDestinationEnvironment: "Test"
      },
      buckets: {
        staticLowerBucket: "es-dev-us-east-1-static",
        templatesHigherBucket: "es-sit-us-east-1-templates"
      },
      isProd: false,
    };
    break;
  default:
    config = {
      apiGateway: {
        REGION: 'us-east-1',
        URL: 'https://dev.mc.nonprod.engage.toyota.com/mcApi'
      },
      auth: {
        APPID: 'eede59e9-ea25-47b8-9841-01ca8b5f9c52',
        AUTHORITY: 'https://login.microsoftonline.com/tmnatest.onmicrosoft.com/',
        URL: 'tmnatest.onmicrosoft.com',
        TENANTID: '9107b728-2166-4e5d-8d13-d1ffdf0351ef',
        ENDPOINT: 'tmnatest.onmicrosoft.com/eede59e9-ea25-47b8-9841-01ca8b5f9c52',
        REDIRECT: 'http://localhost:3000',
        GraphUrl:'https://graph.microsoft.com/v1.0/me/transitiveMemberOf/microsoft.graph.group?$count=true'
      },
      cache: {
        cacheLocation: 'sessionStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to 'true' if you are having issues on IE11 or Edge
      },
      environments: {
        sourceEnvironment: "dev",
        formattedSourceEnvironment: "Dev",
        destinationEnvironment: "test",
        formattedDestinationEnvironment: "Test"
      },
      isProd: false,
    };
    break;
}

export default config;
